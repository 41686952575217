import React from 'react';

import { Main, PrimaryBackground } from '../styles/background';
import { Content } from '../components/layouts/PageContainer/PageContainer';
import { Header } from '../components/layouts/HeaderComponent';
import { Footer } from '../components/layouts/Footer';

import { ImageContainer } from '../components/layouts/ImageContainer';
import { CareersHeader, CareersOpen, CareersValues } from '../components/pageComponents/careers';
import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';

const Careers = () => {
  return (
    <PageContainer>
      <HelmetContainer currentPage={'careers'} title="Careers" />
      <Main noPadding>
        <Header />
        <Content>
          {/*<CareersHeader content={wordpressData.fields.hero} />*/}
          {/*<CareersValues content={wordpressData.fields.values_section} />*/}
        </Content>
      </Main>
      <PrimaryBackground>
        <Content>
          <CareersOpen />
        </Content>
      </PrimaryBackground>
      <Footer />
    </PageContainer>
  );
};

export default Careers;
